import React from "react";
import "./App.css";
import { Footer } from "./Footer";
import { BubbleMenu } from "./BubbleMenu";
import { MainPageBody } from "./MainPageBody";
import { MainHeader } from "./MainHeader";

function App() {
  return (
    <div className="App">
      <BubbleMenu />
      <MainHeader />
      <MainPageBody />
      <Footer />
    </div>
  );
}

export default App;
