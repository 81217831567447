import React, { useState } from "react";
import "./EmailPopupForm.css";
import "./App.css";
import { FormInput, FormTextarea } from "./FormInput";

export function EmailPopupForm({ onClose }) {
  const [isSubmitAttempted, setIsSubmitAttempted] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    message: "",
  });

  const isMandatory = true;

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleButtonClick = (e) => {
    // Check if all mandatory fields are filled
    if (
      isMandatory &&
      (!formData.first_name ||
        !formData.last_name ||
        !formData.email ||
        !formData.message)
    ) {
      e.preventDefault();
      setIsSubmitAttempted(true);
      return;
    }

    handleSubmit(e);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitAttempted(true);

    // Check if all mandatory fields are filled
    if (
      isMandatory &&
      (!formData.first_name ||
        !formData.last_name ||
        !formData.email ||
        !formData.message)
    ) {
      return;
    }

    try {
      const apiEndpoint = process.env.REACT_APP_WEBCONTACT_URL;

      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      setFormData({
        first_name: "",
        last_name: "",
        email: "",
        message: "",
      });

      onClose();
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  return (
    <div className="popup-content">
      <div className="popup-header">
        <h2>CONTACT US</h2>
      </div>
      <form className="popup-form" onSubmit={handleSubmit}>
        <div className="name-fields">
          <FormInput
            type="text"
            placeholder="First Name..."
            name="first_name"
            value={formData.first_name}
            onChange={handleChange}
            isMandatory={isMandatory}
            isSubmitAttempted={isSubmitAttempted}
          />
          <FormInput
            type="text"
            placeholder="Last Name..."
            name="last_name"
            value={formData.last_name}
            onChange={handleChange}
            isMandatory={isMandatory}
            isSubmitAttempted={isSubmitAttempted}
          />
        </div>
        <FormInput
          type="email"
          placeholder="Email..."
          name="email"
          value={formData.email}
          onChange={handleChange}
          isMandatory={isMandatory}
          isSubmitAttempted={isSubmitAttempted}
        />
        <FormTextarea
          placeholder="Message..."
          name="message"
          value={formData.message}
          onChange={handleChange}
          isMandatory={isMandatory}
          isSubmitAttempted={isSubmitAttempted}
        />
        <button
          type="submit"
          className="awake-btn submit-button"
          onClick={handleButtonClick}
        >
          Submit
        </button>
      </form>
    </div>
  );
}
