import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import { Fade } from "react-awesome-reveal";
import "./App.css";
import "./Rottnest.css";

const imagesLo = [
  "/rottnestlo01.jpg",
  "/rottnestlo02.jpg",
  "/rottnestlo03.jpg",
  "/rottnestlo04.jpg",
];
const imagesHi = [
  "/rottnesthi01.jpg",
  "/rottnesthi02.jpg",
  "/rottnesthi03.jpg",
  "/rottnesthi04.jpg",
  "/rottnesthi05.jpg",
];

export function Rottnest() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [images, setImages] = useState(imagesLo);
  const [showModal, setShowModal] = useState(false);

  const updateImages = () => {
    if (window.innerWidth >= 800) {
      setImages(imagesHi);
    } else {
      setImages(imagesLo);
    }
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    updateImages();
    window.addEventListener("resize", updateImages);
    return () => window.removeEventListener("resize", updateImages);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, [images]);

  // Add a side effect to add or remove the "modal-open" class from the body to prevent scrolling when the modal is open
  useEffect(() => {
    if (showModal) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [showModal]);

  return (
    <div id="rottnestparty" className="rottnest-container">
      <div className="section-title">Rottnest Overnight Party - Monday</div>
      <Fade triggerOnce>
        <img
          src={images[currentImageIndex]}
          alt="Rottnest Party Banner"
          className="rottnest-picture"
          onClick={toggleModal}
        />
      </Fade>
      <div className="section-information-container">
        <Fade cascade damping={0.2}>
          <div className="section-information">
            Join us as we close off a chapter in style! We will party
          </div>
          <div className="section-information">
            hard until it's time to watch the sun set over the Indian Ocean.
          </div>
          <div className="section-information">
            Fully licensed bar & open kitchen. Tickets are strictly limited.
          </div>
          <button
            className="awake-btn section-details-btn"
            onClick={toggleModal}
          >
            More Details
          </button>
          <div className="rottnest-information-disclaimer">
            * Priority for overnight tents will be for AWAKE Full Pass holders.
            Please contact us on how to book.
          </div>
        </Fade>
      </div>

      <Modal show={showModal} closeModal={toggleModal}>
        <div className="information-modal-content">
          <div className="section-title information-modal-content-title">
            Rottnest Details
          </div>
          <div className="rottnest-date">Monday, 17th March 2025</div>
          <div className="rottnest-options">
            Full Day & Overnight options available.
          </div>
          <img
            src="/sealink01.jpg"
            alt="SeaLink Rottnest Ferry"
            className="information-modal-content-image-full"
          />
          <p className="information-modal-content-text">
            Departing from the B Shed Ferry Terminal in Fremantle, the SeaLink
            ferry will whisk us all off to Rottnest Island.
          </p>
          <div className="modal-paragraph-with-image">
            <p className="information-modal-content-text">
              A short 30 minutes from Fremantle, you will be on the island in no
              time enjoying the sun, sand and selfies the local favorite, the
              Quokka!
            </p>
            <div className="modal-content-spacer" />
            <img
              src="/quokka01.jpg"
              alt="Quokka"
              className="information-modal-content-image-small"
            />
          </div>
          <p className="information-modal-content-text">
            From there we will be making our way over to Pinky's, one of the
            most famous beaches on Rottnest. Here at Pinky’s is where we will be
            spending our time, though you do have the option to explore the
            island at your leisure.
          </p>
          <img
            src="/pinkys01.jpg"
            alt="Pinkys Beach Club Aerial View"
            className="information-modal-content-image-full"
          />
          <p className="information-modal-content-text">
            Our DJ’s will be kicking off at 12pm on the decks in front of the
            restaurant. For those looking to head home on the day, you will be
            able to enjoy the live music up until your departure just before
            sunset.
          </p>
          <div className="modal-paragraph-with-image">
            <img
              src="/rottnestdancing01.jpg"
              alt="Dancing kizomba on Rottnest Island"
              className="information-modal-content-image-small"
            />
            <div className="modal-content-spacer" />
            <p className="information-modal-content-text">
              There is a fully licensed bar and restaurant on site to enjoy. And
              for those looking to stay over with us, the music will kick on
              until the island curfew of 10pm.
            </p>
          </div>
          <p className="information-modal-content-text">
            If you are looking to stay over we do have a special rate and there
            are a number of options to choose from, including twin bed tents.
          </p>
          <div className="modal-paragraph-with-image">
            <img
              src="/familytentsquarelo.jpg"
              alt="Family tent Rottnest Island Pinkys Beach Club"
              className="information-modal-content-image-small"
            />
            <img
              src="/superiortentsquarelo.jpg"
              alt="Superior tent Rottnest Island Pinkys Beach Club"
              className="information-modal-content-image-small"
            />
          </div>
          <p className="information-modal-content-text">
            All overnight accommodation needs to be booked through us directly,
            and priority will be given to Full Pass holders. Contact us for
            discount code and booking details.
          </p>
          <a href="#contactus" className="awake-btn" onClick={toggleModal}>
            Contact Us
          </a>
        </div>
      </Modal>
    </div>
  );
}
