import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./Footer.css";
import { EmailPopupForm } from "./EmailPopupForm";
import { FormInput } from "./FormInput";

export function Footer() {
  return (
    <div className="Footer">
      <Subscribe />
      <hr className="footer-line" />
      <SocialMediaLinks />
      <hr className="footer-line" />
      <div className="copyright">
        Copyright &copy; AWAKE 2023 | Website version 1.1.3
      </div>
      <ContactUs />
    </div>
  );
}

function Subscribe() {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });
  const [isSubmitAttempted, setIsSubmitAttempted] = useState(false);
  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);

  const isMandatory = true;

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitAttempted(true);

    if (
      isMandatory &&
      (!formData.first_name || !formData.last_name || !formData.email)
    ) {
      return;
    }

    const subscriberData = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email,
      mailing_list: true,
    };

    try {
      const endpoint = process.env.REACT_APP_BASE_URL + "/api/v1/user";

      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(subscriberData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      await response.json();

      console.log("Email subsciption was successful");

      setFormData({
        first_name: "",
        last_name: "",
        email: "",
      });
      setIsSubmitAttempted(false);
      setIsSubmittedSuccessfully(true);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  return (
    <div className="subscribe-section">
      <p>Stay updated with our latest news!</p>
      <div className="input-wrapper">
        <div className="name-input-wrapper">
          <FormInput
            type="text"
            placeholder="First Name..."
            name="first_name"
            value={formData.first_name}
            onChange={handleChange}
            isMandatory={isMandatory}
            isSubmitAttempted={isSubmitAttempted}
          />
          <FormInput
            type="text"
            placeholder="Last Name..."
            name="last_name"
            value={formData.last_name}
            onChange={handleChange}
            isMandatory={isMandatory}
            isSubmitAttempted={isSubmitAttempted}
          />
        </div>
        <FormInput
          type="text"
          placeholder="Enter your email..."
          name="email"
          value={formData.email}
          onChange={handleChange}
          isMandatory={isMandatory}
          isSubmitAttempted={isSubmitAttempted}
        />
        <motion.button
          onClick={handleSubmit}
          initial={{
            background: "linear-gradient(0deg, #ae803c, #ae803c)",
            width: "120px",
            height: "40px",
            borderRadius: "50px",
          }}
          animate={
            isSubmittedSuccessfully
              ? {
                  background: "linear-gradient(0deg, green, green)",
                  width: "40px",
                  height: "40px",
                  borderRadius: "50px",
                }
              : {
                  background: "linear-gradient(0deg, #E1A854, #E1A854)",
                  width: "120px",
                  height: "40px",
                  borderRadius: "50px",
                }
          }
          transition={{ duration: 0.25 }}
          className="awake-btn subscribe-btn"
        >
          {isSubmittedSuccessfully ? (
            <img src="/tick.svg" alt="Success" />
          ) : (
            "Subscribe"
          )}
        </motion.button>
      </div>
    </div>
  );
}

function SocialMediaLinks() {
  return (
    <div className="social-media-section">
      <div>Follow AWAKE</div>
      <div className="social-media">
        <a
          href="https://www.facebook.com/awakefestival"
          target="_blank"
          rel="noopener noreferrer"
          className="social-btn"
        >
          <img src="/FacebookIcon.svg" alt="Facebook" />
        </a>
        <a
          href="https://www.instagram.com/awake_festival"
          target="_blank"
          rel="noopener noreferrer"
          className="social-btn"
        >
          <img src="/InstagramIcon.svg" alt="Instagram" />
        </a>
      </div>
    </div>
  );
}

function ContactUs() {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div id="contactus" className="contact-us-container">
      <button className="awake-btn contact-btn" onClick={handleOpen}>
        Contact Us
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="modal"
          >
            <motion.div
              initial={{ opacity: 0, y: "-100vh" }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: "100vh" }}
              transition={{ duration: 0.75 }}
            >
              <EmailPopupForm onClose={handleClose} />
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <button onClick={handleClose} className="close-button">
                X
              </button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
