import React, { useState } from "react";
import Modal from "./Modal";
import { Rottnest } from "./Rottnest";
import { Hotel } from "./HotelInformation";
import "./Lineup.css";

export function MainPageBody() {
  return (
    <div className="main">
      <BookNowButton />
      <Rottnest />
      <OurLineup />
      <Hotel />
      <CodeOfConduct />
    </div>
  );
}

function BookNowButton() {
  return (
    <div>
      <a href="https://events.humanitix.com/awake-festival-10th-edition">
        <button className="awake-btn book-now-btn">BOOK NOW</button>
      </a>
    </div>
  );
}

function OurLineup() {
  return (
    <div className="our-lineup">
      <h2>OUR LINEUP</h2>
      <ArtistProfile
        profilePic="/saidoksana03.jpg
      "
        artistName="SAID & OKSANA"
        artistDetails="For AWAKE 2025 we once again have Said Mameche from France!
        He's not alone this time though, returning with him is his amazing partner
        Oksana! They will be partying it up with us at AWAKE Festival
        2025 - 10th Edition!"
        youtubeUrl="https://www.youtube.com/embed/x2E0MLI9UuA?si=YMQgnsQ0Z4Kt7JML"
      >
        Coming back to AWAKE 2025 is Said Mameche! But this time he is bringing
        his amazing partner Oksana to party with us in Perth!
      </ArtistProfile>
      <ArtistProfile
        profilePic="/adilsonthelma03.jpg"
        artistName="ADILSON & THELMA"
        artistDetails="This powerhouse couple straight from Angola will bring you ALL THE
        KIZOMBA AND SEMBA goodness! These guys are amazing KUDURO teachers as
        well, so you know the parties are going to be LIT🔥🔥🔥🔥 These
        incredible dancers and teachers are the 2015 champions of the Kizomba
        Competition organized by the TPA (Public Television of Angola) and the
        Tri Champions 2017 of the National Competition of Kizomba and Semba
        Angola"
        youtubeUrl="https://www.youtube.com/embed/8tbG7fUEycw?si=5IQpz2y-T-aO6vWM"
      >
        This powerhouse couple straight from Angola will bring you ALL THE
        KIZOMBA AND SEMBA goodness!
      </ArtistProfile>
      <ArtistProfile
        profilePic="/sanjay01.jpg"
        artistName="Sanjay"
        artistDetails="The Indian Magic, AKA Sanja MJ is a one of the biggest rising 
        stars in Europe at the moment."
        youtubeUrl="https://www.youtube.com/embed/oulXdzip52Q?si=GOU9fFeDKckc_zYG"
      >
        For the first time to Australian shores we are happy to have Sanjay, the
        Indian Magic, at AWAKE 2025.
      </ArtistProfile>
      <ArtistProfile
        profilePic="/fanio01.jpg"
        artistName="Fanio de Araújo"
        artistDetails="Another rising star in the world of Semba & Kizomba! We are 
        excited to welcome Fanio to AWAKE for the first time. Born in Angola, there
        are few people who can move like Fanio! He is a true artist and we are
        excited to have him at AWAKE Festival 2025- 10th Edition!"
        youtubeUrl="https://www.youtube.com/embed/s4bDVwmLsjI?si=yHQPaffOcPKXrGCX"
      >
        We are excited to welcome Fanio to AWAKE for the first time. Another
        rising star in the world of Semba & Kizomba!
      </ArtistProfile>
      <ArtistProfile
        profilePic="/pingusso01.jpg"
        artistName="DJ Pingusso"
        artistDetails="Introducing DJ Pingusso from Luxembourg! He kept us dancing till the
        early hours of the morning last year and he will be bringing his magic
        back to AWAKE Festival 2025- 10th Edition! DJ Pingusso also runs one of
        the biggest Kizomba festivals in the world - Kizomba Luxembourg!"
        youtubeUrl="https://www.youtube.com/embed/DmcdkKwxpAU?si=xlpuG5NX9ANkCedZ"
      >
        He kept us dancing till the early hours last year and he will be doing
        it again at AWAKE Festival 2025.
      </ArtistProfile>
      <ArtistProfile
        profilePic="/guelas01.jpg"
        artistName="DJ Guelas"
        artistDetails="Music is what makes us tick! And that's precisely why we are bringing back
        DJ extraodinaire- DJ Guelas! He kept us all dancing and partying and the
        energy, feel and love we felt from the Kizomba room last year was
        magical! He is BACK to bring you back that some MAGIC! We are thrilled
        to have him for AWAKE Festival 2025- 10th Edition!"
        youtubeUrl="https://www.youtube.com/embed/4yps5h2yCEc?si=zriz_HuTV7BBW0hh"
      >
        Music is what makes us tick! And that's why we are bringing back DJ
        extraodinaire- DJ Guelas!
      </ArtistProfile>
    </div>
  );
}

function ArtistProfile({
  profilePic,
  artistName,
  artistDetails,
  children,
  youtubeUrl,
}) {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div className="artist-card">
      <img src={profilePic} alt={profilePic} className="profile-picture" />
      <div className="artist-info">
        <div className="artist-name">{artistName}</div>
        <p>{children}</p>
        <button className="awake-btn artist-details-btn" onClick={toggleModal}>
          More Details
        </button>
      </div>

      <Modal show={showModal} closeModal={toggleModal}>
        <div className="artist-content">
          <div className="artist-content-name">{artistName}</div>
          {/* Embed YouTube video */}
          <div className="video-container">
            <iframe
              width="560"
              height="315"
              src={youtubeUrl}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="artist-details-container">
            <p>{artistDetails}</p>
          </div>
        </div>
      </Modal>
    </div>
  );
}

function CodeOfConduct() {
  return (
    <div id="codeofconduct" className="code-of-conduct-container">
      <div className="section-title">AWAKE Code of Conduct </div>
      <div className="code-of-conduct-information">
        At AWAKE, we are committed to providing a safe and inclusive environment
        for all our guests. To ensure this, we expect all artists and attendees
        to adhere to the AWAKE Code of Conduct, which can be found below.
      </div>
      <a
        href={`${process.env.PUBLIC_URL}/pdf/awake_code_of_conduct.pdf`}
        download="CodeOfConduct.pdf"
      >
        <button className="awake-btn code-of-conduct-btn">
          Download Code of Conduct
        </button>
      </a>
    </div>
  );
}
