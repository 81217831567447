import React from "react";
import "./MainHeader.css";

export function MainHeader() {
  return (
    <div className="MainHeader">
      <div className="Background">
        <img
          src="/bluegradientbg.jpg"
          alt="Blue Gradient Background"
          className="BackgroundImage"
        />
        <img
          src="/pinkgradientbg.jpg"
          alt="Pink Gradient Background"
          className="BackgroundImage"
        />
        <img
          src="/peachgradientbg.jpg"
          alt="Peach Gradient Background"
          className="BackgroundImage"
        />
      </div>
      <img src="logolo.png" alt="Logo" className="Logo" />
      <img src="artistslo.png" alt="Artists" className="Artists" />
      <h3>MARCH 14-18 2025</h3>
    </div>
  );
}
