import React, { useEffect, useCallback, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import { flushSync } from "react-dom";
import Modal from "./Modal";
import { Fade } from "react-awesome-reveal";
import "./App.css";

const OPTIONS = { loop: true, auto: true };
const TWEEN_FACTOR = 2.5;

export function Hotel() {
  const slides = [
    "/ibis01.jpg",
    "/ibis02.jpg",
    "/ibis03.jpg",
    "/ibis05.jpg",
    "/ibis04.jpg",
  ];
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div id="hotelSection" className="hotel-container">
      <div className="section-title">Venue: Ibis Perth</div>
      <HotelCarousel slides={slides} options={OPTIONS} />
      <div className="section-information-container">
        <Fade cascade damping={0.2}>
          <div className="section-information">
            Welcome to the official AWAKE Festival 2025 hotel! The Ibis
          </div>
          <div className="section-information">
            Perth is located in the heart of Perth, close to all the action!
          </div>
          <div className="section-information">
            With two rooms and 3am finish, you can party all night and
          </div>
          <div className="section-information">
            sleep all day at the same location. Book your room now!
          </div>
          <button
            className="awake-btn section-details-btn"
            onClick={toggleModal}
          >
            More Details
          </button>
        </Fade>
      </div>

      <Modal show={showModal} closeModal={toggleModal}>
        <div className="information-modal-content">
          <div className="section-title information-modal-content-title">
            Ibis Hotel Details
          </div>
          <div className="rottnest-options">Two Rooms | 3am Finish</div>
          <img
            src="/hotelmap.jpg"
            alt="Map for the Ibis Perth Hotel"
            className="information-modal-content-image-full"
          />
          <p className="information-modal-content-text">
            The Ibis Perth is located in the heart of Perth, close to
            restaurants, shops and public transport.
          </p>
          <div className="modal-paragraph-with-image">
            <p className="information-modal-content-text">
              Walking distance to the underground train station, with easy
              accesss to Perth Airport and Fremantle for the ferry to Rottnest
              Island.
            </p>
            <div className="modal-content-spacer" />
            <img
              src="/ibis02.jpg"
              alt="Ibis Perth Hotel front entrance"
              className="information-modal-content-image-small"
            />
          </div>
          <p className="information-modal-content-text">
            Once again we have two rooms for the evening parties, including the
            main ballroom which is undergoing reventions in 2024. There is a
            fully licenced bar & restaurant on site to enjoy.
          </p>
          <img
            src="/ibis01.jpg"
            alt="Ibis Perth Hotel main ballroom"
            className="information-modal-content-image-full"
          />
          <p className="information-modal-content-text">
            The sconond room is located near the main ballroom and will be open
            in the evenings as the Kizomba room until 3am.
          </p>
          <div className="modal-paragraph-with-image">
            <img
              src="/ibis05.jpg"
              alt="Rubix Bar at Ibis Perth Hotel"
              className="information-modal-content-image-small"
            />
            <div className="modal-content-spacer" />
            <p className="information-modal-content-text">
              Attached to the hotel is the Rubix bar and Restaurant. There is
              also a Coles nearby and plenty of other options for dining.
            </p>
          </div>
          <p className="information-modal-content-text">
            With plenty of options, including twin rooms for those looking to
            share with a friend, and starting at $190 a night the Ibis Perth is
            the perfect place to stay for AWAKE Festival 2025.
          </p>
          <div className="modal-paragraph-with-image">
            <img
              src="/ibis03.jpg"
              alt="Ibis Perth Hotel room option 1"
              className="information-modal-content-image-small"
            />
            <img
              src="/ibis04.jpg"
              alt="Ibis Perth Hotel room option 2"
              className="information-modal-content-image-small"
            />
          </div>
          <p className="information-modal-content-text">
            To book and further information, click the button below.
          </p>
          <a
            href="https://all.accor.com/hotel/1773/index.en.shtml?utm_source=google&utm_medium=cpc&utm_campaign=ppc-ibi-mar-goo-au-en-au-mix-sear-au&utm_term=mar&utm_content=au-en-all-all&gad_source=1&gclid=CjwKCAjwqf20BhBwEiwAt7dtdZhx2c_W8BkKTgKV0exkAK8wcAid3YHoN2U-kVAMkXwVcwcjsjdoXhoCZuoQAvD_BwE#section-rooms"
            className="awake-btn"
            onClick={toggleModal}
          >
            Book
          </a>
        </div>
      </Modal>
    </div>
  );
}

const numberWithinRange = (number, min, max) =>
  Math.min(Math.max(number, min), max);

function HotelCarousel(props) {
  const { slides, options } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(options, [Autoplay()]);
  const [tweenValues, setTweenValues] = useState([]);

  const onScroll = useCallback(() => {
    if (!emblaApi) return;

    const engine = emblaApi.internalEngine();
    const scrollProgress = emblaApi.scrollProgress();

    const styles = emblaApi.scrollSnapList().map((scrollSnap, index) => {
      let diffToTarget = scrollSnap - scrollProgress;

      if (engine.options.loop) {
        engine.slideLooper.loopPoints.forEach((loopItem) => {
          const target = loopItem.target();
          if (index === loopItem.index && target !== 0) {
            const sign = Math.sign(target);
            if (sign === -1) diffToTarget = scrollSnap - (1 + scrollProgress);
            if (sign === 1) diffToTarget = scrollSnap + (1 - scrollProgress);
          }
        });
      }
      const tweenValue = 1 - Math.abs(diffToTarget * TWEEN_FACTOR);
      return numberWithinRange(tweenValue, 0, 1);
    });
    setTweenValues(styles);
  }, [emblaApi, setTweenValues]);

  useEffect(() => {
    if (!emblaApi) return;

    onScroll();
    emblaApi.on("scroll", () => {
      flushSync(() => onScroll());
    });
    emblaApi.on("reInit", onScroll);
  }, [emblaApi, onScroll]);

  return (
    <div className="embla">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {slides.map((slide, index) => (
            <div
              className="embla__slide"
              key={index}
              style={{
                ...(tweenValues.length && { opacity: tweenValues[index] }),
              }}
            >
              <img className="embla__slide__img" src={slide} alt="Slide" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
