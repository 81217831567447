import React from "react";

export const FormInput = ({
  type,
  placeholder,
  name,
  value,
  onChange,
  isMandatory,
  isSubmitAttempted,
}) => {
  const styles = {
    backgroundColor:
      isMandatory && !value && isSubmitAttempted ? "#ce9191" : "white",
    border: "none",
  };

  return (
    <input
      type={type}
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
      style={styles}
    />
  );
};

export const FormTextarea = ({
  placeholder,
  name,
  value,
  onChange,
  isMandatory,
  isSubmitAttempted,
}) => {
  const styles = {
    backgroundColor:
      isMandatory && !value && isSubmitAttempted ? "#ce9191" : "white",
    border: "none",
  };

  return (
    <textarea
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
      style={styles}
    ></textarea>
  );
};
